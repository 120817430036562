<template>
  <div>
    <b-card-body class="p-0 tickets-table">
      <table class="table table-sm table-bordered table-hover mb-0">
        <thead>
          <tr>
            <th width="160">วันที่</th>
            <th width="220">ชนิดหวย</th>
            <th width="120">งวด</th>
            <th width="70">รายการ</th>
            <th width="100">ยอด</th>
            <th width="100">ส่วนลด</th>
            <th width="100">รวม</th>
            <th width="100">ถูกรางวัล</th>
            <th>หมายเหตุ</th>
            <th width="100"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items"
            :key="item._id"
            :class="[
              {'alert-danger': item.status==='Canceled'},
              {'alert-warning': item.status==='Refund'}
            ]">
            <td class="text-left">
              <i class="fas fa-times text-danger" v-if="item.status==='Canceled'"></i>
              <i class="fas fa-undo text-warning fa-sm" v-if="item.status==='Refund'"></i>
              {{item.createdAt | dispDateTime("DD/MM/YYYY HH:mm:ss")}}
            </td>
            <td>[{{item.market.groupTitle}}] - {{item.market.marketTitle}}</td>
            <td class="text-center">{{item.round.roundDate}}</td>
            <td class="text-center">{{item.summary.length}}</td>
            <td class="text-right text-info">{{item.summary.amount | amountFormat(2)}}</td>
            <td class="text-right text-danger">{{item.summary.discount | amountFormat(2)}}</td>
            <td class="text-right text-primary">{{item.summary.credit | amountFormat(2)}}</td>
            <td>
              <div v-if="item.isWon===true" class="text-success text-right">{{item.summary.won | amountFormat(2)}}</div>
              <div v-else-if="item.isWon===false" class="text-danger text-center">ไม่ถูกรางวัล</div>
            </td>
            <td class="text-center">{{item.remarks}}</td>
            <td class="text-left">
              <button class="btn btn-outline-info btn-sm" @click="viewTicket(item._id)"><i class="fas fa-list"></i></button>
              <button v-if="item.status==='Accepted'" class="btn btn-outline-danger btn-sm ml-2" :disabled="item.status==='Canceled'" @click="cancelTicket(item._id)"><i class="far fa-trash-alt"></i></button>
            </td>
          </tr>
          <tr v-if="!items.length">
            <td colspan="10" class="text-center">ไม่มีข้อมูล</td>
          </tr>
        </tbody>
        <tfoot v-if="items.length">
          <tr>
            <th colspan="3" class="text-center">รวม</th>
            <th class="text-center">{{sum.length}}</th>
            <th class="text-right text-info">{{sum.amount | amountFormat(2, '0.00')}}</th>
            <th class="text-right text-danger">{{sum.discount | amountFormat(2, '0.00')}}</th>
            <th class="text-right text-primary">{{sum.credit | amountFormat(2, '0.00')}}</th>
            <th class="text-right text-success">{{sum.won | amountFormat(2, '0.00')}}</th>
            <th></th>
            <th></th>
          </tr>
        </tfoot>
      </table>
    </b-card-body>

    <ViewTicketModal :is-show="isShowTicketModal" :ticket-id="viewTicketId" @close="isShowTicketModal=false" />
  </div>
</template>
<script>
import ViewTicketModal from '@/views/report/components/ViewTicketModal'
import ReportService from '@/services/ReportService'
import Swal from 'sweetalert2'
import cryptoRandomString from 'crypto-random-string'
import cAlert from '@/helpers/alert'

export default {
  name: 'AllTickets',
  components: {
    ViewTicketModal
  },
  props: ['items'],
  data() {
    return {
      isShowTicketModal: false,
      viewTicketId: null
    }
  },
  computed: {
    sum() {
      return this.items.reduce((sum, item)=>{
        if(item.status !== 'Canceled' && item.status !== 'Refund') {
          sum.amount += item.summary.amount
          sum.credit += item.summary.credit
          sum.discount += item.summary.discount
          sum.length += item.summary.length

          if(item.isWon)
            sum.won += item.summary.won
        }

        return sum
      }, {
        amount: 0,
        credit: 0,
        discount: 0,
        won: 0,
        length: 0
      })
    }
  },
  methods: {
    viewTicket(ticketId) {
      this.isShowTicketModal = true
      this.viewTicketId = ticketId
    },
    cancelTicket(ticketId) {
      const uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})
      Swal.fire({
        text: 'ยืนยันยกเลิกโพย!',
        icon: 'info',
        confirmButtonText: 'ยืนยัน',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก'
      })
      .then((res)=>{
        if(res.isConfirmed) {
          this.isLoading = true
          ReportService.cancelTicket(ticketId, {uniqueCode}).then((response)=>{
            if(response.success) {
              this.$store.dispatch('reloadBalance')
              this.$notify({
                type: 'success',
                title: 'ยกเลิกโพยแล้ว!'
              })
            }else{
              throw new Error(response?.message)
            }
          })
          .catch((e)=>{
            cAlert({
              ...e,
              title: 'ผิดพลาด!',
              text: e?.message || 'ยกเลิกโพยไม่สำเร็จ',
              icon: 'error',
              confirmButtonText: 'OK'
            })
          })
          .finally(()=>{
            this.isLoading = false
            this.loadTickets()
          })
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.tickets-table {
  background-color: #FFF;
  min-height: 50px;

  table {
    border: 0;
    thead {
      tr {
        th {
          font-size: 85%;
          vertical-align: middle;
          text-align: center;
          border-top: 0;
        }
        th:first-child {
          border-left: 0;
        }
        th:last-child {
          border-right: 0;
        }
      }
    }
    tbody {
      tr {
        td {
          vertical-align: middle;
          font-size: 85%;
        }
        td:first-child {
          border-left: 0;
        }
        td:last-child {
          border-right: 0;
        }
      }
    }
    tfoot {
      tr {
        th:first-child {
          border-left: 0;
        }
        th:last-child {
          border-right: 0;
        }
      }
    }
  }
}
</style>
