<template>
  <div style="padding-bottom: 50px;">
    <strong>รายการแทง</strong>

    <DateFilter @submit="search" />

    <div v-if="dateText.start===dateText.end">ข้อมูลวันที่ <span class="text-primary">{{dateText.start}}</span></div>
    <div v-else>
      ข้อมูลรวม <span class="text-primary">{{totalDay}}</span> วัน
      ตั้งแต่วันที่ <span class="text-primary">{{dateText.start}}</span>
      <span> ถึง
        <span class="text-primary">{{dateText.end}}</span>
      </span>
    </div>

    <b-card no-body class="mb-1 position-relative">
      <b-card-header header-tag="nav">
        <b-nav card-header tabs>
          <b-nav-item @click="activeTab='AllTickets'" :active="activeTab==='AllTickets'">รายการทั้งหมด</b-nav-item>
          <b-nav-item @click="activeTab='TicketByType'" :active="activeTab==='TicketByType'">ตามชนิดหวย</b-nav-item>
        </b-nav>
      </b-card-header>

      <AllTickets v-if="activeTab==='AllTickets'" :items="items" />
      <TicketByType v-if="activeTab==='TicketByType'" :items="items" />

      <loading
        :active="isLoading"
        :can-cancel="false"
        :is-full-page="false"
        background-color="#EBEDEF"
        :height="30"
        :width="30"
        color="#007bff"
      />
    </b-card>

    <div class="small font-weight-light pl-2">
      *** หมายเหตุ ***
      <div><i class="fas fa-undo text-warning fa-sm"></i> <span>คืนเงิน</span></div>
      <div><i class="fas fa-times text-danger"></i> <span>ยกเลิก</span></div>
    </div>
  </div>
</template>
<script>
import DateFilter from '@/views/report/components/DateFilter'
import ReportService from '@/services/ReportService'
import Swal from 'sweetalert2'
import cryptoRandomString from 'crypto-random-string'
import moment from '@/helpers/moment'
import AllTickets from './ticket/AllTickets'
import TicketByType from './ticket/TicketByType'
import cAlert from '@/helpers/alert'

export default {
  name: 'Tickets',
  components: {
    DateFilter,
    AllTickets,
    TicketByType
  },
  data() {
    return {
      activeTab: 'AllTickets',
      data: null,
      isLoading: true,
      filters: {
        startDate: null,
        endDate: null
      }
    }
  },
  computed: {
    items() {
      return this?.data?.items || []
    },
    dateText() {
      if(!this.data)
        return {
          start: null,
          end: null
        }

      return {
        start: moment(this.data.filters.startDate).add(543, 'y').format('D MMMM YYYY'),
        end: moment(this.data.filters.endDate).add(543, 'y').format('D MMMM YYYY')
      }
    },
    totalDay() {
      if(!this.data)
        return ''

      return this.data.filters.total || 0
    }
  },
  methods: {
    search(filters) {
      this.filters = {
        ...filters,
        startDate: filters.date.start,
        endDate: filters.date.end,
      }
      this.loadTickets()
    },
    loadTickets() {
      this.isLoading = true
      ReportService.getTicketsDate(this.filters).then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response?.message)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'โหลดรายการแทงไม่สำเร็จ',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    }
  }
}
</script>
